import React from 'react'
import epassi from '../assets/images/Epassi_logo_color.png'

const Footer = props => (
  <footer id="footer">
    <section id="yhteystiedot">
      <h2>Ota yhteyttä</h2>
      <p>
          Toimin pääasiassa ajanvarauksella ja tavoitat minut soittamalla,
          laittamalla WhatsApp- tai tekstiviestin tai sähköpostilla. Joogan
          tuntiaikataulut löytyvät ajankohtaista osiosta. Ilmoittauduthan tunnille
          etukäteen. Joogamatot ja -välineet ovat käytettävissä, mutta halutessasi
          voit tuoda myös oman maton.
      </p>

    </section>
    <section>
      <h2>&nbsp;</h2>
      <dl className="alt">
        <dt>Osoite</dt>
        <dd>
            Kirkkokatu 32 B 26, 80100 Joensuu<br />
            <a href="https://goo.gl/maps/FSBcjLjbqMkqWvp2A" target="_blank">Kartta</a>
        </dd>
        <dt>Puhelin</dt>
        <dd>050 5367398</dd>
        <dt>Sähköposti</dt>
        <dd>
          <a href="mailto:hannele@studiohetki.fi">hannele@studiohetki.fi</a>
        </dd>
        <dt>Maksutavat</dt>
          <dd>
              <ul>
                  <li>Käteinen</li>
                  <li>Kortti</li>
                  <li><img src={epassi} alt="epassi" width="80"/></li>
              </ul>
          </dd>
      </dl>

      <ul className="icons">
        {/*<li>*/}
        {/*  <a href="https://facebook.com" className="icon fa-facebook alt">*/}
        {/*    <span className="label">Facebook</span>*/}
        {/*  </a>*/}
        {/*</li>*/}
        <li>
          <a href="https://www.instagram.com/hyvinvointistudiohetki/" className="icon fa-instagram alt">
            <span className="label">Instagram</span>
          </a>
        </li>
      </ul>
    </section>

    <p className="copyright">
      &copy; Hyvinvointistudio Hetki
    </p>
  </footer>
)

export default Footer
